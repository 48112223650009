@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;

  @screen md {
    font-size: 13px;
  }

  @screen lg {
    font-size: 16px;
  }
}

body {
  font-family: "Jost", sans-serif;
  background-color: #f9f9f9;
}
